.App {
  text-align: center;
  background-color: #000;
  min-height: 100vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.sretno {
  color: rgb(226, 169, 62);
  font-size: 40px;
  font-weight: 600;
}

.botun {
  cursor: pointer;
  outline: 0;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #0d6efd;
  border-color: #0d6efd;
  margin-left: 30px;
}
.botun:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.naslov {
  color: #0d6efd;
  font-weight: 500;
  text-transform: uppercase;
}

.redoslijed {
  color: wheat;
}

.sređeno {
  color: wheat;
  list-style: none;
}

.neizabrani {
  color: white;
  list-style: none;
}

.popis {
  color: wheat;
  margin-left: 30px;
}
